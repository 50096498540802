import './App.css';
import Navbar from './components/navbar';
import ChoosableImage from './components/choosableImage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import StoryPage from './pages/storyPage';

const imageUrls = [
  {
    imageUrl: "/images/signora_spazzatura_spalle.jpg",
    text: "BELLINZAGO'S MONSTER",
    urlText: "bellinzagosmonster"
  },
  {
    imageUrl: "/images/polizia_carica.jpg",
    text: "UG ROMA - CLIMATE CHANGE",
    urlText: "romaclimatechange"
  },
]

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/stories" />} />
        <Route path="/stories" element={imageUrls.map((obj) => {
          return <ChoosableImage text={obj.text} imgUrl={obj.imageUrl} urlText={obj.urlText} key={obj.urlText} />
        })} />
        <Route path='/stories/:urlText' element={<StoryPage />} />
      </Routes>
    </>
  );
}

export default App;
