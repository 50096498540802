import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const imageUrls = [
  {
    urlText: "bellinzagosmonster",
    images: [
      "/images/signora_raccoglie.jpg",
      "/images/spazzatura_con_signora_colori.jpg",
      "/images/volontario_spazzatura_garage_1.jpg",
      "/images/lancia_infangata.jpg",
      "/images/signora_spazzatura_spalle.jpg",
      "/images/volontari_cappotto.jpg",
      "/images/abbraccio_signora.jpg",
      "/images/ritratto_signora_1.jpg",
      "/images/passeggino.jpg",
      "/images/volontario_sotto_5.jpg",
      "/images/volontario_sotto_3.jpg",
      "/images/ragazza_mobile.jpg",
      "/images/bagno_3.jpg",
      "/images/strada_rifiuti_5.jpg"
    ],
    storyName: "BELLINZAGO'S MONSTER",
    storyDescription: "AFTERMATH OF THE FLOOD IN BELLINZAGO.",
    storyIndex: 0
  },
  {
    urlText: "romaclimatechange",
    images: [
      "/images/signora_raccoglie.jpg",
      "/images/spazzatura_con_signora_colori.jpg",
      "/images/volontario_spazzatura_garage_1.jpg",
      "/images/lancia_infangata.jpg",
      "/images/abbraccio_signora.jpg",
      "/images/ritratto_signora_1.jpg",
      "/images/passeggino.jpg",
      "/images/volontario_sotto_5.jpg",
      "/images/ragazza_mobile.jpg",
      "/images/bagno_3.jpg",
      "/images/strada_rifiuti_5.jpg"
    ],
    storyIndex: 1
  },
];

function StoryPage() {
  const [images, setImages] = useState([]);
  const [storyName, setStoryName] = useState();
  const [storyDescription, setStoryDescription] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;
    const splittedUrl = currentUrl.split("/");
    const storyUrlText = splittedUrl[splittedUrl.length - 1];

    // Find the story matching the URL text
    const matchedStory = imageUrls.find(story => story.urlText === storyUrlText);

    if (matchedStory) {
      setImages(matchedStory.images);
      setStoryName(matchedStory.storyName);
      setStoryDescription(matchedStory.storyDescription);
    } else {
      console.error('No story matched the URL text.');
      navigate(`/`)
    }
  }, []);

  if (images.length === 0) {

  }

  return (
    <div>
      <div className="p-64 flex flex-col justify-center items-center">
        <span className="text-white font-BebasNeue tracking-wider text-2xl sm:text-7xl ">
          {storyName}
        </span>
        <span className="text-white font-BebasNeue tracking-wider text-sm sm:text-xl pt-2 italic">
          {storyDescription}
        </span>
      </div>
      {images.map((imgPath, index) => (
        <img
          key={index}
          className="h-auto w-full"
          src={imgPath}
          alt="Story Image"
        />
      ))}
    </div>
  );
}

export default StoryPage;