import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ChoosableImage({ text, imgUrl, urlText }) {
  const [isHovered, setIsHovered] = useState(false);
  const [mounted, setMounted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Set mounted to true after a delay to trigger fade-in animation
    const timeout = setTimeout(() => {
      setMounted(true);
    }, 200); // Adjust delay as needed

    // Clean up timeout on component unmount
    return () => clearTimeout(timeout);
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    navigate(`/stories/${urlText}`, { state: { urlText } })
  };

  const grayscaleLevel = isHovered ? 'grayscale(60%)' : 'grayscale(20%)';
  return (
    <button
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleClick()}
    >
      <img
        className={`h-auto w-full transition-opacity duration-500 ease-in-out ${mounted ? 'opacity-100' : 'opacity-0'}`}
        style={{ filter: grayscaleLevel }}
        src={imgUrl}
        alt="Polizia Carica"
      />
      <span className={`absolute top-1/2 left-1/4 transform -translate-x-1/2 text-white font-BebasNeue tracking-wider text-2xl sm:text-7xl ${mounted ? 'opacity-100' : 'opacity-0'}`}>
        {text}
      </span>
    </button>
  );
}

export default ChoosableImage;
